@import "_config.sass";


button.fancybox-close-small:hover {
  color: #000 !important;
}


.fancybox-opened {
  .fancybox-skin {
    @include box-shadow(0,
      1px,
      7px,
      0,
      rgba(0,
        0,
        0,
        0.8));
    @include border-radius(1px);
  }
}

.slide-is-loading .fancybox-content {
  visibility: hidden;
}

.fancybox-title-inside-wrap {
  padding: 5px;
  font-size: 0.95em;
  line-height: 1.4em;
  color: #fff;
  background: #000;
  margin-top: 2px;
}


// Fancybox menu popups
.fs-menus {
  .fancybox-slide {
    padding: 5px;
  }


  .menu-list {
    width: 100%;
    max-width: 500px;
    margin: 0; //margin: 5px;
    padding: 20px;

    ul.action-links a {
      text-transform: none;
      font-family: $default-family;
    }
  }

  .menu-header,
  .block-title {
    font-size: 12px;
    font-family: $default-family;
    color: $off-black;
    margin: 0;
    padding: 0;
    letter-spacing: normal;
  }

  div.cancel {
    border-top: solid 1px #eee;
    padding-top: 10px;
    display: inline-block;
    width: 100%;
    clear: both;
    margin-top: 10px;

    a {
      text-align: center;
      font-size: 21px;
      display: block;
      padding: 10px;
      text-decoration: none;
    }
  }

  ul.editor-buttons {
    display: block !important;
    position: relative;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    @include border-radius(0);
  }

  #tab-trigger {
    display: none;
  }

  div.tabs {
    margin: 0;
    padding: 0;
  }

  ul.primary,
  ul.action-links,
  ul {
    padding: 0;
    margin: 0;
    width: 100%;
    display: block;
    list-style: none;
    float: left;
    display: block !important;
    background: transparent;
    border: none;
    position: relative;

    .label {
      display: inline;
    }

    li {
      margin: 7px 0 !important;
      width: 100%;
      padding: 0 !important;
      list-style: none;
      float: none;
      display: block !important;
      border: none;

      .fa {
        width: 25px;
        text-align: center;
        margin-right: 7px;
      }
    }

    a {
      float: none;
      font-size: 20px;
      padding: 0 10px;
      color: #333;
      display: block;
      line-height: 50px;
      height: 50px;
      margin: 0;
      text-decoration: none;
      background-image: none !important;
      background: none;
      @include border-radius(2px); //@include gradient(none);
      @include box-shadow();
      border: solid 1px #ddd;
      @include text-shadow(none);

      &:hover {
        background-color: #ddd; //@include gradient(#ddd,#eee);
        border-color: #bbb;
      }

      &.active {
        //background-color: #ddd;
        // @include gradient(#ddd,#eee);
        //font-weight: bold; //@include box-shadow(none);
        // border-color: #bbb;
        display: none;
      }
    }
  }
}

.fancybox-container {
  z-index: 9999999;

  .cancel-message {
    display: none;
  }

  .login-panel {
    padding: 0;
    border: none;
    margin: 0;
  }

  div.messages {
    margin: 0 0 1em 0;
  }

  .feedback {
    font-size: 1.5em;
    padding: 1em;
  }

  form {
    h1 {
      font-size: 22px;
      font-family: $default-family;
      border-bottom: solid 1px #eee;
      color: $bright-blue;
      margin: 0;
      padding: 0 40px 0.5em 0;
      font-weight: normal;
      letter-spacing: normal;
    }

    input.form-select,
    input.form-text,
    textarea {
      font-size: 16px;
      width: 100%;
    }

    #edit-actions,
    .form-actions {
      margin: 0;
      padding: 2em 0 1em 0;

      input.form-submit {
        // line-height: 2.4em;
        // height: 2.4em;
        text-transform: uppercase; //font-weight: normal;
      }
    }
  }
}

.fancybox-container.sharing {
  .helpers-share {
    //font-size: 35px;
    float: none;
  }

  .social-shares {
    margin: 10px 20px 0 0;

    &:last-child {
      margin-right: 0;
    }

    .sharing-icon {
      width: 50px;
      height: 50px;
    }
  }

  // @todo: are h5s used here?
  h5 {
    font-size: 20px;
    float: none;
    clear: both;
    display: block;
    padding: 0;
  }
}

.fancybox-container {
  .fancybox-slide--iframe .fancybox-content {
    position: relative;
    padding-bottom: 44.8%;
    padding-top: 0;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .fancybox-toolbar button,
  button.fancybox-close-small,
  .fancybox-navigation button {
    opacity: inherit;
    background: none !important;
  }
}

.fancybox-bg {
  background-color: #000;
}

.fancybox-is-open .fancybox-bg {
  opacity: .9;
}

.portfolio-selector-wrapper,
.ajax-content-wrapper {
  width: 100%;
  max-width: 700px;
  min-width: 250px;
  padding: 0;

  .modal-header {
    padding: 0 20px;
    border-bottom: solid 1px #eee;
    line-height: 50px;
    height: 50px;
    display: block;

    h3 {
      margin: 0;
      padding: 0;
      line-height: inherit;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .fb-footer {
    border-top: solid 1px #eee;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding: 10px;
  }

  .scroll-wrapper {
    overflow: auto;
    padding: 10px 20px; //0 20px 20px 20px;
    //max-height: 200px;
  }

  &.hasFooter .scroll-wrapper {
    bottom: 50px;
  }

  .view-user-comments {
    .comment {
      .content {
        //font-size: 13px !important;
      }
    }
  }
}

.portfolio-selector-wrapper {
}

.scroll-wrapper {
  overflow: auto;
}

.ajax-modal-large {
  .ajax-content-wrapper {
    max-width: 1200px;
    margin: 44px;
  }
}

.ajax-modal-small {
  .ajax-content-wrapper {
    max-width: 500px;
    margin: 44px;

    .profile-lists {
      .view-content {
        margin-left: 0;
      }

      .views-row {
        //width: 100%;
        padding-bottom: 1em;
        border-bottom: 1px solid #eee;
        /* padding-bottom: 5px;
          margin-bottom: 5px; */
        //padding: 10px 0;
      }
    }
  }
}

.ajax-modal-undefined {
  .ajax-content-wrapper {
    padding: 0;
  }
}

.portfolio-selector-wrapper {
  .portfolio-selector-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: 50px;
    line-height: 50px;
    border-top: solid 1px #ccc;
    text-align: center;

    .submit-button {
      background: $bright-blue;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 16px;
      padding: 0 10px;
      line-height: 32px;
      height: 32px;
      display: inline-block;
      @include border-radius(3px);
      border: solid 1px $bright-blue;

      &:hover {
        color: $bright-blue;
        background: #fff;
      }
    }
  }
}

.ajax-modal-small {
  .fancybox-slide > * {
    max-width: 550px;
  }
}

.ajax-modal-medium {
  .fancybox-slide > * {
    max-width: 900px;
  }
}

.ajax-modal-large {
  .fancybox-slide > * {
    max-width: 1500px;
  }
}


#sidebar-first-content.fancybox-content {
  padding: 30px;
  width: 100%;
  max-width: 600px;

  #block-fs_helpers-popular_tags .content .item-list li {
    font-size: 16px;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  .block-title {
    color: $bright-blue;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 10px 0;
    border-bottom: solid 1px #eee;
    padding-bottom: 5px;
  }

  .block {
    padding-bottom: 20px;
  }
}

.ajax-modal-undefined,
.ajax-modal-small,
.ajax-modal-medium,
.ajax-modal-large {
  .fancybox-is-sliding .fancybox-slide,
  .fancybox-slide--current,
  .fancybox-slide--next,
  .fancybox-slide--previous {
    padding: 3em;
  }

  .portfolio-selector-wrapper,
  .ajax-content-wrapper {
    margin: 0;
    height: 100%;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }

  .scroll-wrapper {
    overflow: auto;
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: inline-block;
    float: left;
  }

  .portfolio-selector-wrapper {
    .scroll-wrapper {
      bottom: 50px;
    }
  }
}

.confirmModalDialog {
  font-size: 20px;
  max-width: 500px;
  padding: 1.5em;
  border-radius: 3px;

  h3 {
    margin: 0;
    border-bottom: solid 1px #eee;
    margin-bottom: 0.5em;
    font-size: 20px;
    padding-bottom: 5px;
  }

  .dialogButtons {
    text-align: center;
    padding: 1em 0 0 0;
    margin: 0;

    button {
      margin: 0 0.5em;
    }
  }

  #modalConfirm,
  .modalConfirm,
  .modalCancel {
    font-size: inherit;
    text-decoration: none;
    line-height: 2em;
    display: inline-block;
    padding: 0 1em;
    border-radius: 3px;
    cursor: pointer;
  }

  .modalCancel {
    color: #666;
    background: #eee;

    &:hover {
      background: #ddd;
    }
  }

  .dialogMessage {
    padding: 0.5em 0;
    line-height: 1.4em;
  }
}

/**
* ======================================
* Fancybox Photo Viewer
* ======================================
*/

.fsPhotoViewer2 {
  .comment .content {
    font-size: 14px !important;
    line-height: 1.3em !important;
    // margin-left: 40px;
    // padding-bottom: 3px;
  }

  .view-profile-photos {
    .pagination {
      display: none;
    }
  }

  .fancybox-bg {
    opacity: .95; //background-color: #fff;
  }

  .bottom {
    width: 100%;
    background: none; //margin-left: 350px;
  }

  a.comment-close {
    display: none;
  }

  .comment-icon {
    display: none;
  }

  .fancybox-expand {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    z-index: 100000;
  }

  &.fancybox-container .fancybox-slide--iframe {
    .fancybox-content iframe {
      background: #000;
    }
  }

  .comment-form {
    font-family: $header-family;
    display: inline-block;
    padding: 0;
    background: none;
    border: none;
    border-bottom: solid 1px #eee;
    padding-bottom: 13px;

    textarea {
      font-size: 13px;
    }

    .comment-form-picture {
      width: 40px;
    }

    .field-name-comment-body {
      margin-left: 50px;
    }

    .form-actions {
      padding-bottom: 0 !important;
    }
  }

  .comments div.links ul.inline,
  .comments div.links ul.links.inline {
    font-size: 11px;
    float: right;
  }

  .comment-header {
    #comment-count,
    h2 {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: normal;
    }
  }

  #comments {
    margin: 0;
  }

  .comments {
    .indented {
      margin-left: 10px;

      .indented {
        margin-left: 0 !important;
      }
    }
  }

  .comment {
    border: none;

    div.rate-up-down li {
      line-height: 1em;
    }

    div.links {
      display: inline-block;
      width: 100%;
      vertical-align: middle;
    }

    .user-picture {
      width: 30px;
    }
  }

  .image-slide-wrapper {
    height: 100%;
    width: 100%;
    background: none;
    border: none; //solid 2px #444;
    margin: 0;
    padding: 20px;
    overflow: hidden;
  }

  .image-wrapper {
    //margin-left: 350px;
    text-align: center;
    height: 100%; //margin-right: 420px;
    background: #000; // overflow: hidden;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      @include center-vertical();
    }
  }

  .node-sidebar {
    //position: relative;
    .flag-chosen-contest-entry {
      float: right;
    }
  }

  .fancybox-button {
    width: 2em;
    height: 2em;
    line-height: 2em;
    padding: 0;
    font-size: 18px;
  }

  .fancybox-stage-wrapper {
    position: relative; //border: solid 25px transparent;
    width: 100%;
    height: 100%;
    display: inline-block; //border-bottom: 70px;
    border-bottom: solid 70px transparent;
  }

  .fancybox-stage {
    right: $fbColWidth;
    background-color: #0f0f0f;

    button {
      background-color: rgba(0, 0, 0, 0.5);
      margin: 10px;
      color: #ccc;
      padding: 0 0.65em;

      &:hover {
        color: #fff;
      }
    }
  }

  .compensate-for-scrollbar,
  .fancybox-enabled body {
    .fancybox-stage {
      right: $fbColWidth + 17px;
    }
  }

  .fancybox-col {
    width: $fbColWidth;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    overflow: auto;

    a.tab-trigger {
      top: 5px;
      right: 5px;
    }
  }

  .fancybox-inner {
    overflow-y: scroll;
    padding: 27px;
    padding-bottom: 0;
    -webkit-overflow-scrolling: touch;
  }

  &.not-expanded .fancybox-image-wrap {
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }

  &.expanded {
    .fancybox-stage {
      right: 0;
    }

    .fancybox-navigation {
      right: 0;
    }

    .fancybox-toolbar,
    .fancybox-col,
    .fancybox-bottom {
      display: none;
    }

    .fancybox-inner {
      overflow: hidden;
      padding: 0;
    }

    .fancybox-stage-wrapper {
      border: none;
    }
  }

  .fancybox-col {
    .user-info {
      padding: 0; //20px;
      line-height: normal;
      font-family: $header-family;

      .user-picture {
        width: 70px;
      }

      .submitted {
        margin-left: 80px;
        margin-bottom: 0;
      }

      .username {
        font-size: 21px;
        font-weight: 400;
        display: inline-block;
        line-height: 1em;
        padding-top: 4px;
      }

      .user-follow {
        display: block;
      }

      .title {
        font-size: 24px;
        letter-spacing: $header-spacing;
        line-height: 1em;
        margin-left: -60px;
        clear: both;
        padding: 20px 0 10px 0;
        display: none;

        a {
          text-decoration: none;
          font-weight: 800;
          color: #000;
        }
      }
    }
  }

  .node-date {
    font-size: 12px;
    padding-bottom: 10px;
    color: #999;
    font-style: italic;
  }

  &.not-expanded .fancybox-caption {
    display: none;
  }

  .node-title {
    font-weight: 800;
    font-size: 25px;
    letter-spacing: $header-spacing;
    font-family: $header-family;
    padding-top: 15px;
    line-height: 1em;
    display: inline-block;
    width: 100%;
    clear: both;

    a {
      color: #000;
      text-decoration: none;
    }
  }

  .field-name-body {
    font-size: 14px;
    line-height: 1.3em;
  }

  .node-exif {
    padding: 5px 0 10px 0;
    border-top: none;
    border-bottom: solid 1px #eee;
    border-bottom: none;
    margin-top: 0;
    font-family: $header-family;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    line-height: normal;

    h2 {
      color: $bright-blue;
      text-transform: uppercase;
      font-size: 14px;
      border-bottom: solid 1px #eee;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }

    .image-details {
      font-size: 12px; //padding-top: 10px;
      //border-top: solid 1px #eee;
      .label,
      label {
        color: $bright-blue;
        text-transform: uppercase;
        font-size: 11px; // font-family: $default-family;
        font-weight: bold;
      }

      .camera {
        font-size: 18px;
        padding-top: 0;
        margin-top: 0;
      }
    }

    .exif-info-wrapper {
      padding: 10px 0 0 0;
      font-size: 14px;
      line-height: 1.4em;
    }

    .exif-image-thumb {
      width: 45px;
    }
  }

  .nsfw-message {
    font-size: 2em;
    padding: 2em;
    line-height: 1.3em;
    text-align: center;

    .button {
      font-size: 0.8em;
    }
  }

  .user-follow .flag-wrapper {
    font-family: $header-family;
    display: inline-block; //width: 100%;
    width: 120px;
    font-size: 13px;
    text-transform: uppercase;
    padding-top: 5px;

    .flag {
      display: block;
      width: 100%; //padding: 0 1em;
      line-height: 2.2em;

      &.unflag-action {
        background: #eee;
      }
    }
  }

  .fancybox-toolbar {
    z-index: 99999;
  }

  .image-controls {
    position: absolute;
    left: 0;
    z-index: 99998;
    right: 0;
    bottom: 0;
    padding: 10px;
  }

  .fancybox-bottom {
    margin-top: -60px;

    .ajax-block {
      min-height: 200px;
    }

    .ajax-block-wrapper {
      .ajax-block-padding {
        max-width: 1500px;
        width: 100%;
        margin: 1em auto;

        .block-title {
          color: #fff;
          text-transform: uppercase;
          font-size: 16px;
          padding-left: 40px;
          margin: 0;
        }
      }

      &.user-port-footer {
        .ajax-block-padding {
          max-width: 100%;
        }
      }

      &.related-media-footer {
        background: rgba(255, 255, 255, 0.95);
        padding: 50px 27px;
        margin-top: 70px;
        min-height: 500px;
        margin-left: -27px;
        margin-right: -27px;

        .ajax-block-padding {
          padding: 0 40px;

          .block-title {
            padding-left: 0;
            color: $bright-blue;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
          }

          .image-tags {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
            margin-left: 30px;

            .item-list li {
              font-size: 14px;
            }
          }
        }

        .ajax-block {
          margin-top: 10px;
          display: inline-block;
          width: 100%;

        }
      }
    }

    .no-gridzy {
      .views-row {
        width: auto;
        padding-right: 20px; //height: 170px;
        .video,
        .photo {
          height: 230px;

          .file {
            height: 100%;
          }
        }

        img {
          max-width: 100%;
          /* height: auto; */
          vertical-align: middle;
          height: 100% !important;
          width: auto;
        }
      }
    }
  }

  .potd-badge {
    position: fixed;
    z-index: 999;
    left: 10px;
    right: auto;
    top: 0px;
  }

  &.expanded {
    .potd-badge {
      top: 90px;
    }
  }

  .rate-another {
    display: none;
  }

  .actions-wrapper {
    max-width: 100% !important;

    .action-item {
      line-height: 65px;
      padding: 0;
      margin: 0;

      .count {
        font-size: 18px;
      }

      .vote_count {
        .count {
          font-size: 27px;
        }
      }

      i {
        font-size: 26px;
      }

      .rate-widget-fivestar {
        padding: 0 10px;
        margin: 0;
        line-height: normal;
        float: none;
        width: auto;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }

    .action-buttons {
      .action-item {
        .count {
          font-size: 22px;
        }

        i {
          font-size: 40px;
          vertical-align: top;
        }
      }

      a:hover {
        color: #fff;
      }
    }

    .rate-fivestar-btn-filled,
    .rate-fivestar-btn-empty {
      width: auto;
      margin-right: 5px;
    }

    .vote-wrapper.results {
      margin-left: 1em;

      .rate-fivestar-btn-filled,
      .rate-fivestar-btn-empty {
        width: auto;
        margin-right: 3px;

        i {
          font-size: 16px !important;
        }
      }
    }

    .fivestar-container .inprogress {
      opacity: .2 !important;
    }

    .fivestar-label .rate-description {
      font-size: 13px;
      margin-bottom: 5px;
    }
  }

  .fivestar-container {
    .rating-header {
      color: #fff;
      font-size: 12px;
    }
  }

  /*     &.fancybox-show-caption{
      .fancybox-metadata-wrap{
          opacity: 1;
          visibility: visible;
      }
    } */
  .image-controls {
    opacity: 0;
  }

  &.fancybox-show-caption {
    .image-controls {
      opacity: 1;
      visibility: visible;
    }
  }

  .fancybox-navigation {
    right: 440px;
    position: absolute;
    left: 22px;
    height: 100%;
    bottom: 0; //80px;
    opacity: 0;

    button.fancybox-arrow-fa {
      background: rgba(0, 0, 0, 0.5) !important;
      display: block;
      color: #ccc;
      position: absolute;
      top: 50%;
      left: 10px;
      z-index: 99999;
      font-size: 35px;
      padding: 0 0.6em;
      text-align: center;
      margin-top: -50px;

      &:hover {
        color: #fff;
      }

      &:disabled {
        color: #ccc;
        opacity: 0.3;
      }
    }

    button.fancybox-arrow-fa--right {
      right: 10px;
      left: auto;
    }
  }

  &.fancybox-show-nav {
    .fancybox-navigation {
      opacity: 1;
    }
  }

  .fancybox-caption-wrap {
    background: none;
    padding: 0;
    border: none;
    bottom: auto;
    top: 0;
    padding: 0;
    right: 0;
    position: absolute;
    left: 0;
    height: auto;
    display: inline-block;
    width: 100%;
    margin: 0;
    height: auto;
  }

  .fancybox-caption__body {
    margin-right: 100px;
    overflow: visible;
  }

  .fancybox-caption {
    font-family: $header-family;
    padding: 15px 10px;
    border: none;
    display: inline-block;
    width: 100%;
    margin: 0;
    bottom: auto;
    top: 0;
    background: none;
    text-align: left;

    a:hover {
      text-decoration: none;
    }

    .user-info {
      .user-picture {
        max-width: 60px;
      }

      .user-follow {
        line-height: 28px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 1em;
        margin-top: -5px;
      }

      .submitted {
        font-size: 19px;
        margin-left: 70px;
        color: #ddd;
        padding-top: 0; //5px;
        font-weight: normal;

        .username {
          color: #ddd;
          font-weight: 100; //padding-bottom: 5px;
          line-height: 30px;
          display: inline-block;
          vertical-align: middle;
          border: solid 1px transparent; //border: solid 1px transparent;
          @include text-shadow(0 1px 1px rgba(0,
            0,
            0,
            0.7));
        }

        .title {
          padding: 3px 0;
          font-size: 24px;
          color: #fff;
          line-height: 1em;
          @include text-shadow(0 1px 1px rgba(0,
            0,
            0,
            0.7));

          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
}

.fsGalleryBrowser {
  .fancybox-caption__body {
    color: #ddd;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: $bright-blue;
      }
    }

    .download-button-wrapper a {
      text-decoration: none;
      color: #000;

      &:hover {
        color: #fff;
      }
    }
  }
}


.fancybox-stage .link-info {
  padding: 2em;
  font-size: 16px;
  max-width: 600px;

  textarea {
    resize: none; //display: inline-block;
    height: auto;
    min-height: 0;
    width: 100%;
  }

  .description {
    font-size: 0.9em;
    color: #888;
    padding: 0.2em 0;
  }

  code {
    display: inline-block;
    margin: 0;
    padding: 0.3em;
  }
}

// small
@media only screen and (max-width: $mobile-max-width),
only screen and (max-device-width: $mobile-max-width) {
  .node.ajax_node {
    padding: 0;
  }
  .fancybox-container {
    .fancybox-slide--iframe .fancybox-content {
      max-width: 100%;
      width: 100%;
      padding-bottom: 56.23%;
    }

    .fancybox-navigation {
      opacity: 0;
      visibility: hidden;
    }

    &.fancybox-show-caption {
      .fancybox-navigation {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .fsPhotoViewer2 {
    .flag-wrapper {
      width: 90px;
      font-size: 11px;

      i {
        display: none;
      }

      .flag {
        line-height: 1.9em;
      }
    }

    a.comment-close {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      color: #999;
      line-height: 45px;
      padding: 0 10px;
      z-index: 99;
      display: block;
      min-width: 30px;
      text-align: center;
    }

    .comment-icon {
      display: block;
    }

    .fancybox-button {
      width: 44px;
      height: 44px;
      line-height: 44px;
    }

    .fancybox-inner {
      padding: 0;
      overflow: hidden;
    }

    .potd-badge {
      position: fixed;
      z-index: 999;
      left: 5px;
      top: 80px !important;
      text-align: left; //display: none;
      .trophy {
        width: 30px;
        height: 30px !important;
      }
    }

    .fancybox-expand {
      display: none;
    }

    &.not-expanded {
      .fancybox-caption {
        display: block;
      }
    }

    .fancybox-caption {
      .user-info {
        .title {
          display: block;
          clear: none;
          margin: 0;
          letter-spacing: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          padding-right: 30px !important;
        }

        .username {
          padding-top: 1px;
          font-size: 14px;
          line-height: 1em;
          border: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          padding-right: 30px !important;
        }

        .user-follow {
          line-height: normal;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 0;

          .flag-wrapper {
            margin: 0;
            padding: 0;
            font-size: 11px;
            width: auto;
            display: inline-block;

            a {
              padding: 0 5px;
              line-height: 2em;
            }
          }
        }
      }
    }

    .image-controls .rating-wrapper.has-tabs {
      margin: 0;
    }

    .image-controls {
      background: none; //background-color: rgba(0, 0, 0, 0.6);
      margin: 0;
      padding: 0;
    }

    .image-controls {
      .rate-another {
        display: none !important;
      }

      padding: 1px;

      .fivestar-wrapper {
        float: none;
        vertical-align: bottom;
      }

      .actions-wrapper {
        display: inline-block;
        width: 100%;

        .action-item {
          padding: 0;

          .rate-widget-fivestar {
            padding: 0 5px;
          }

          .count {
            font-size: 14px;
          }

          i {
            font-size: 25px;
          }
        }

        .fivestar-wrapper {
          i {
            font-size: 30px;
          }

          .rate-fivestar-btn-filled,
          .rate-fivestar-btn-empty {
            width: auto;
          }
        }

        .fivestar-label {
          .rate-description {
            font-size: 11px;
            margin-bottom: 2px;
          }
        }

        .fivestar-container.action-item {
          margin: 0;
        }

        .vote-wrapper {
          .vote-help {
            //margin-left: 0 !important;
            //margin-top: 15px !important;
            vertical-align: middle !important;
            float: none !important;
            display: inline-block !important;
            /* margin: 0; */
            margin: 0 0 15px 0 !important;
          }

          &.results {
            margin: 0 0 0 2px;
            display: inline-block;
            width: auto;
          }
        }

        .action-buttons {
          display: inline-block;
          position: absolute;
          bottom: 10px;
          right: 10px;

          .action-item {
            padding: 0;
            margin: 1px;

            i {
              font-size: 25px;
              vertical-align: middle;
            }

            .count {
              font-size: 20px !important;
            }
          }
        }

        .vote_count {
          vertical-align: bottom;
          float: none;
          padding-bottom: 5px;
          padding-left: 5px;
          padding-right: 5px;

          .count {
            font-size: 25px;
          }
        }
      }
    }

    .action-item {
      //background-color: transparent !important;
      &.bookmarks {
        display: none;
      }

      &.comment-icon {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &.add-icon {
        position: absolute;
        bottom: 0;
        right: 70px;

        .fa-plus {
          font-size: 16px;
        }
      }
    }

    .fancybox-stage {
      right: 0;
      width: 100%;
      left: initial;
      @include transition(all .25s ease-in-out);
    }

    .fancybox-col {
      display: block;
      top: 0;
      left: 100%;
      right: initial;
      bottom: 0;
      position: fixed;
      z-index: 99999;
      padding: 1em;
      width: 100%;
      @include transition(all .25s ease-in-out);

      .node-author {
        display: none;
      }

      .node-title {
        position: fixed;
        font-size: 18px;
        /* height: 50px; */
        padding: 0 30px;
        border-bottom: solid 1px #eee;
        line-height: 45px;
        top: 0;
        left: 100%;
        right: 0;
        background-color: #fff;
        z-index: 9;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        @include transition(all .25s ease-in-out);
      }

      .node-sidebar {
        margin-top: 40px;
      }

      a.tab-trigger {
        top: 0;
        right: 0;
        position: absolute;
        line-height: 45px;
        padding: 0 10px;
        z-index: 99;
      }
    }

    &.comment-col-open {
      .fancybox-col {
        left: 0;

        .node-title {
          //position: fixed;
          left: 0;
          min-height: 43px;
        }
      }

      .fancybox-stage {
        right: 100%;
      }

      a.tab-trigger {
        position: fixed;
        background: transparent;
        border: none;
      }

      /* a.comment-close{
        position: fixed;
       }
 */
    }

    .fancybox-stage-wrapper {
      border: none;
    }

    .fancybox-navigation {
      right: 0;
      left: 0;
      bottom: 0;

      button.fancybox-arrow-fa--right,
      button.fancybox-arrow-fa--left {
        font-size: 25px;
        padding: 0;
        width: 35px;
        text-align: center;
      }

      button.fancybox-arrow-fa--left {
        left: 0;
      }

      button.fancybox-arrow-fa--right {
        right: 0;
      }
    }

    .fancybox-caption {
      padding: 7px;
      display: block;

      .user-info {
        .user-picture {
          max-width: 35px;
        }

        .submitted {
          margin-left: 45px;
          font-size: 13px;
          min-height: 65px;

          .username {
            line-height: 1em;
            border: none;
          }

          .title {
            font-size: 15px;
          }
        }

        /* .flag-wrapper {
          width: 35px;
        } */
        .user-follow {
          line-height: auto;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 0;
        }

        .unfollow-label,
        .follow-label,
        .following-label {
          //height: 24px;
          min-width: auto;

          i {
            padding: 0 !important;
            vertical-align: middle !important;
          }

          .text {
            //display: none;
          }
        }
      }
    }
  }

  .fancybox-content {
    padding: 25px;
  }
  #modal-form-wrapper {
    //padding: 2em 1em;
  }
  .ajax-modal-undefined,
  .ajax-modal-small,
  .ajax-modal-medium,
  .ajax-modal-large {
    .fancybox-is-sliding .fancybox-slide,
    .fancybox-slide--current,
    .fancybox-slide--next,
    .fancybox-slide--previous {
      padding: 5px;
    }
  }

}

// WIDE
// ============================
@media only screen and (min-width: $wide-min-width) {


}
